<template>
    <div>
        <el-table :data="tableData" size="mini">
            <el-table-column prop="number" label="Nummer" width="120" />
            <el-table-column prop="name" label="Namn" />
            <el-table-column prop="accountType" label="Kontotyp">
                <template slot-scope="scope">
                    {{ translate(scope.row.accountType) }}
                </template>
            </el-table-column>
            <el-table-column prop="subLedgerType" label="Reskontra">
                <template slot-scope="scope">
                    {{ translateSubLedger(scope.row.subLedgerType) }}
                </template>
            </el-table-column>
            <el-table-column prop="sru" label="SRU" />
            <el-table-column prop="interimAssetAccount" label="Tillgångskonto" width="120" />
            <el-table-column prop="interimDebtAccount" label="Debetkonto" width="120" />
            <el-table-column width="100" align="right">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-tooltip class="item" effect="dark" content="Detaljer" placement="top">
                            <el-button type="primary" class="p-1" @click="handleEdit(scope.row.accountPlanItemId)">
                                <i class="far fa-magnifying-glass-plus" />
                            </el-button>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="Ta bort" placement="top">
                            <el-button type="danger" @click="handleDelete(scope.row.accountPlanItemId)" class="p-1">
                                <i class="far fa-trash" />
                            </el-button>
                        </el-tooltip>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    props: {
        tableData: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        handleEdit(id) {
            this.$emit("edit", id);
        },

        handleDelete(id) {
            this.$emit("delete", id);
        },

        translate(item) {
            const translations = {
                asset: "Tillgångskonto",
                debt: "Skuldkonto",
                cost: "Kostnadskonto",
                revenue: "Vinstkonto",
            };
            return translations[item];
        },
        translateSubLedger(item) {
            const translations = {
                none: "Ingen",
                customer: "Kundreskontra",
                supplier: "Leverantörsreskontra",
                inventory: "Lager",
            };
            return translations[item];
        },
    },
};
</script>
